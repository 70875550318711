import axios from 'axios';
import { SERVER_URL } from './api';

// @ts-check

/**
 * Login
 *
 * @param {{ formRef: import('react').RefObject<HTMLFormElement> }} state
 */
const login = async ({ formRef }) => {
  const LOGIN_ENDPOINT = `${SERVER_URL}/login`;

  const formData = new FormData(formRef.current);
  const { username, password } = Object.fromEntries(formData.entries());

  console.log('Logging in as', username, 'with password', password.replace(/./g, '*'));

  try {
    let { status, data } = await axios({
      method: 'post',
      responseType: 'json',
      url: LOGIN_ENDPOINT,
      data: {
        username: username,
        password: password,
      },
    });

    if (status === 200 && data.user && data.token) {
      let user = data.user;
      let token = data.token;

      setUser({
        username: user.username,
        token: token,
      });

      return { user, token };
    }
  } catch (error) {
    return { ...error.response.data, status: error.response.status };
  }
};

/**
 * Register a new user
 *
 * @param {{username: string, name: string, email: string, password: string}}
 */
const register = async ({ username, name, email, password, type }) => {
  const SIGNUP_ENDPOINT = `${SERVER_URL}/user`;

  try {
    let { status } = await axios({
      method: 'post',
      responseType: 'json',
      url: SIGNUP_ENDPOINT,
      data: {
        username: username,
        name: name,
        email: email,
        password: password,
        type: type,
      },
    });

    return status;
  } catch (error) {
    return error.response.status;
  }
};

/**
 * Set localStorage userdata: username and token
 *
 * @param {{username: string, token: string}} user
 */
const setUser = (user) => window.localStorage.setItem('jusp_user', JSON.stringify(user));

const isBrowser = typeof window !== 'undefined';

/**
 * Get localStorage userdata: username and token
 */
const getUser = () =>
  isBrowser && window.localStorage.getItem('jusp_user') !== 'undefined'
    ? JSON.parse(window.localStorage.getItem('jusp_user'))
    : undefined;

/**
 * Remove the localStorage data
 *
 * @param {function} callback
 */
const logout = (callback) => {
  if (isBrowser && window.localStorage.getItem('jusp_user'))
    window.localStorage.removeItem('jusp_user');

  callback();
};

function logoutOn401(statusCode) {
  if (statusCode === 401) {
    logout(() => {
      window.location.reload();
    });
    return true;
  }

  return false;
}

/**
 *
 * @param {object} error
 * @returns {boolean}
 */
const isUnauthorized = (error) => {
  const msg = error.response.data.message;

  return msg === 'unauthorized';
};

export { login, register, setUser, getUser, logout, isUnauthorized, logoutOn401 };
